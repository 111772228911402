<div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
  <div class="row g-1 px-2 py-3 flex-grow-1">
    <div class="col-auto d-flex align-items-center">
      @if (reaction().reaction === FeedbackReaction.PARTY) {
        <i class="fa-light fa-glass-cheers text-primary fa-2x align-icon"></i>
      }
      @if (reaction().reaction === FeedbackReaction.HEART) {
        <i class="fa-light fa-heart fa-2x text-danger align-icon"></i>
      }
      @if (reaction().reaction === FeedbackReaction.LIKE) {
        <i class="fa-light fa-thumbs-up fa-2x text-tertiary align-icon"></i>
      }
      @if (reaction().reaction === FeedbackReaction.INTERROGATION) {
        <i class="fa-light fa-question fa-2x text-warning align-icon"></i>
      }
    </div>
    <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
      <div class="row">
        <div class="col-auto">
          <b>{{ reaction().firstName }} {{ reaction().lastName }} {{ 'fbs.reaction-card.a réagi à votre feedback' | translate }}</b>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <small>{{ reaction().message | truncate: 70 : true }}</small>
        </div>
      </div>
    </div>
  </div>
</div>
