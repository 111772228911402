import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from 'common';

@Component({
    selector: 'hiji-search-card',
    templateUrl: './search-card.component.html',
    styleUrls: ['./search-card.component.scss'],
    standalone: false
})
export class SearchCardComponent implements OnInit {
  private authService = inject(AuthService);

  manualHierarchicalManagement: boolean = false;
  constructor() {
    if (this.authService.userBundle) this.manualHierarchicalManagement = this.authService.modules.moduleGlobal.manualHierarchicalManagement;
  }

  ngOnInit(): void {}
}
