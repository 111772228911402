<a class="text-body" [routerLink]="['/event', event.idFormationEvent, string_to_slug(event.name | ms)]">
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-calendar-star fa-2x text-tertiary align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>{{ event.name | ms }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col">
            @if (DateUtils.isSameDay(event.startDate, event.endDate)) {
              <small>{{ event.startDate | localizedDate }}</small>
            }
            @if (!DateUtils.isSameDay(event.startDate, event.endDate)) {
              <small>
                {{
                'global.Du \{\{startDate\}\} au \{\{endDate\}\}'
                | translate
                : {
                startDate: event.startDate | localizedDate,
                endDate: event.endDate | localizedDate,
              }
              }}
            </small>
          }
        </div>
      </div>
    </div>
  </div>
</div>
</a>
