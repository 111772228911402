import { Component, OnInit, input } from '@angular/core';
import { FeedbackDTO } from 'common';

@Component({
    selector: 'hiji-feedback-card',
    templateUrl: './feedback-card.component.html',
    styleUrls: ['./feedback-card.component.scss'],
    standalone: false
})
export class FeedbackCardComponent implements OnInit {
  readonly feedback = input<FeedbackDTO>(undefined);

  constructor() {}

  ngOnInit(): void {}
}
