<a
  class="text-body"
  [routerLink]="[
    '/formation',
    expectation().idFormation,
    string_to_slug(expectation().formationName | ms),
    'session',
    expectation().idFormationSession,
    string_to_slug(expectation().formationSessionName),
    expectation().user.idUser,
    string_to_slug(expectation().user.firstName + ' ' + expectation().user.lastName),
    'manager',
    'expectations',
  ]"
>
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-pen-to-square fa-2x text-tertiary align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>
              {{
                'mif.expectations.Définir mes attentes business pour la Formation \{\{formationName\}\} de \{\{user\}\}'
                  | translate: { formationName: expectation().formationName | ms, user: expectation().user.firstName + ' ' + expectation().user.lastName }
              }}
            </b>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
