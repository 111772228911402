import { Component, Input, OnInit, inject } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, DateUtils, FormationEVADTO, Ic2ToastrService } from 'common';

@Component({
    selector: 'hiji-mif-eva-card',
    templateUrl: './mif-eva-card.component.html',
    styleUrls: ['./mif-eva-card.component.scss'],
    standalone: false
})
export class MifEvaCardComponent implements OnInit {
  authService = inject(AuthService);
  private ic2ToastrService = inject(Ic2ToastrService);
  translate = inject(TranslateService);

  @Input()
  eva: FormationEVADTO;
  warningEndDate: boolean = false;
  string_to_slug = string_to_slug;

  ngOnInit(): void {
    this.warningEndDate = DateUtils.isPastOrNow(this.eva.dateEVA);
  }

  showToastIfUserIsTargetEVA() {
    if (this.eva.user.idUser === this.authService.userBundle.user.idUser) {
      this.ic2ToastrService.showDanger(this.translate.instant('mif.Cette action est declenchée par votre manager'));
    }
  }
}
