@if (acceptMode) {
  <div class="bg-white border rounded-3 p-3">
    <div class="row">
      <div class="col">
        {{ 'home.card.manager-invitation.Invitation à rejoindre l\'équipe de {{name}}' | translate:{name:data().firstName+' '+data().lastName} }}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <button class="btn btn-outline-medium" [disabled]="loading" type="button" (click)="refuse()">
          <i class="fa-light fa-check me-2"></i>
          {{ 'global.Refuser' | translate }}
          @if (loading) {
            <i class="fa-light fa-spinner spinning ms-2"></i>
          }
        </button>
      </div>
      <div class="col">
        <button class="btn btn-primary" [disabled]="loading" type="button" (click)="accept()">
          <i class="fa-light fa-check me-2"></i>
          {{ 'global.Accepter' | translate }}
          @if (loading) {
            <i class="fa-light fa-spinner spinning ms-2"></i>
          }
        </button>
      </div>
    </div>
  </div>
} @else {
  <div class="bg-white border rounded-3 pointer animate__animated animate__infinite animate__pulse" (click)="acceptMode = true">
    <div class="row g-1">
      <div class="col-auto d-flex align-items-center justify-content-center align-icon">
        <hiji-user-avatar
          style="width: 80px; height: 80px"
          [idUser]="data().idUser"
          [avatarExtension]="data().avatarExtension"
          [defaultBlueAvatar]="true"
        ></hiji-user-avatar>
      </div>
      <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <span class="fw-bold">{{ data().firstName }} {{ data().lastName }}</span>
            {{ 'home.card.manager-invitation.vous invite dans son équipe' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
}
