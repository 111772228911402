<a class="text-body" [routerLink]="['/teamboard', teamboard.idTeamBoard]">
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-users-rectangle fa-2x text-tertiary align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            @if (authService.userBundle != null) {
              @if (authService.userBundle.user.idUser !== teamboard.manager.idUser) {
                <b>
                  {{
                  'teamboard.home-card.Consulter le dashboard équipe de \{\{user\}\}'
                  | translate
                  : {
                  user: teamboard.manager.firstName + ' ' + teamboard.manager.lastName,
                }
                }}
              </b>
            }
            @if (authService.userBundle.user.idUser === teamboard.manager.idUser) {
              <b>
                {{ 'teamboard.home-card.Editer mon dashboard équipe' | translate }}
              </b>
            }
          }
        </div>
      </div>

      @if (!teamboard.sawTeamboard) {
        <div class="row">
          <div class="col">
            <small class="text-danger">
              {{ 'teamboard.home-card.À consulter, disponible depuis \{\{date\}\}' | translate: { date: teamboard.creationDate | localizedDate } }}
            </small>
          </div>
        </div>
      }
      @if (teamboard.sawTeamboard && !teamboard.sawLastBrief && teamboard.lastBriefDate !== null) {
        <div class="row">
          <div class="col">
            <small class="text-danger">
              {{
              'teamboard.home-card.Nouveau brief à consulter, disponible depuis \{\{date\}\}' | translate: { date: teamboard.lastBriefDate | localizedDate }
              }}
            </small>
          </div>
        </div>
      }
    </div>
  </div>
</div>
</a>
