<a
  class="text-body"
  [routerLink]="[
    '/formation',
    action().idFormation,
    string_to_slug(action().formationName | ms),
    'session',
    action().idFormationSession,
    string_to_slug(action().formationSessionName),
    'participant',
    'actions',
    'answer',
    'j42',
  ]"
>
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-pen-to-square fa-2x text-tertiary align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>
              {{
                'mif.action.Evaluer les actions mises en place lors de la formation \{\{formationName\}\}'
                  | translate: { formationName: action().formationName | ms }
              }}
            </b>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
