import { Component, OnInit, inject } from '@angular/core';
import { EnvService, string_to_slug } from '@ic2/ic2-lib';
import { AuthService, Config, HijiBundle, HomeDTO, HomeService } from 'common';

@Component({
    selector: 'hiji-home-skillsup',
    templateUrl: './home-skillsup.component.html',
    styleUrl: './home-skillsup.component.scss',
    standalone: false
})
export class HomeSkillsupComponent implements OnInit {
  private homeService = inject(HomeService);
  private authService = inject(AuthService);
  envService = inject<EnvService<Config>>(EnvService);

  data: HomeDTO = null;
  loading: boolean = true;
  string_to_slug = string_to_slug;
  encouragePicture: boolean = false;

  constructor() {
    this.encouragePicture =
      (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension === null &&
      !this.authService.modules.frontConfiguration.hideHomeEncouragePictureCard;
  }

  ngOnInit(): void {
    this.homeService.getTasks().execute((data) => {
      this.data = data;
      this.loading = false;
    });
  }
}
