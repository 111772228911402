import { Component, OnInit, input } from '@angular/core';
import { FeedbackReaction, FeedbackUserReactionDTO } from 'common';

@Component({
    selector: 'hiji-fbs-reaction-card',
    templateUrl: './fbs-reaction-card.component.html',
    styleUrls: ['./fbs-reaction-card.component.scss'],
    standalone: false
})
export class FbsReactionCardComponent implements OnInit {
  readonly reaction = input<FeedbackUserReactionDTO>(undefined);
  FeedbackReaction: typeof FeedbackReaction = FeedbackReaction;

  constructor() {}

  ngOnInit(): void {}
}
