<a class="text-body" [routerLink]="['/inquiry360', inquiryToCreate.idInquiry, string_to_slug(inquiryToCreate.name | ms)]">
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-clipboard-list fa-2x text-tertiary align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto fw-bold">{{ inquiryToCreate.name | ms }}</div>
        </div>
        <div class="row">
          @if (inquiryToCreate.deployed) {
            <div class="col-auto">{{ 'inquiry360-create-card.Visualiser les réponses à votre questionnaire' | translate }}</div>
          }
          @if (!inquiryToCreate.deployed && inquiryToCreate.updateDate == null) {
            <div class="col-auto">
              {{ 'inquiry360-create-card.Lancez votre 360' | translate }}
            </div>
          }
        </div>
        @if (inquiryToCreate.deployed && inquiryToCreate.autoObsDraft) {
          <div class="row">
            <div class="col-auto text-success">
              <i class="fa-light fa-clipboard me-1"></i>
              {{ 'inquiry360-create-card.Auto-observation en brouillon, à envoyer avant le' | translate }}
              <span class="fw-bold" [class.text-danger]="warningEndDate">{{ inquiryToCreate.endDate | localizedDate }}</span>
              @if (warningEndDate) {
                <i class="fa-light fa-exclamation-triangle text-danger ms-2"></i>
              }
            </div>
          </div>
        }
        @if (inquiryToCreate.deployed && !inquiryToCreate.autoObsDone && !inquiryToCreate.autoObsDraft) {
          <div class="row">
            <div class="col-auto text-tertiary">
              <i class="fa-light fa-warning me-1"></i>
              {{ 'inquiry360-create-card.Auto-observation à réaliser avant le' | translate }}
              <span class="fw-bold" [class.text-danger]="warningEndDate">{{ inquiryToCreate.endDate | localizedDate }}</span>
              @if (warningEndDate) {
                <i class="fa-light fa-exclamation-triangle text-danger ms-2"></i>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</a>
