<a
  class="text-body"
  [routerLink]="
    survey.answered && !survey.draft
      ? ['/visualize/survey', survey.idCampaign, string_to_slug(survey.name | ms)]
      : ['/survey', survey.idCampaign, string_to_slug(survey.name | ms)]
  "
  >
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-poll fa-2x text-tertiary align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>{{ survey.name | ms }}</b>
          </div>
        </div>

        @if (survey.draft) {
          <div class="row">
            <div class="col-auto text-success">
              <i class="fa-light fa-clipboard me-1"></i>
              {{ 'survey.card.En brouillon, à envoyer avant le' | translate }}
              <span class="fw-bold" [class.text-danger]="warningEndDate">{{ survey.endDate | localizedDate }}</span>
              @if (warningEndDate) {
                <i class="fa-light fa-exclamation-triangle text-danger ms-2"></i>
              }
            </div>
          </div>
        }
        @if (!survey.draft) {
          <div class="row">
            <div class="col">
              <small>{{ 'survey.card.Fin le' | translate }} {{ survey.endDate | localizedDate }}</small>
            </div>
          </div>
        }
      </div>
      @if (survey.answered) {
        <div class="col-auto d-flex align-items-center">
          <i class="fa-light fa-check fa-2x px-4 text-success"></i>
        </div>
      }
    </div>
  </div>
</a>
