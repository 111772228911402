import { Component, Input, inject, input } from '@angular/core';
import { AuthService, HomeDTO, TeamBoardLiteDTO } from 'common';

@Component({
    selector: 'hiji-teamboard-card',
    templateUrl: './teamboard-card.component.html',
    styleUrl: './teamboard-card.component.scss',
    standalone: false
})
export class TeamboardCardComponent {
  authService = inject(AuthService);

  readonly data = input<HomeDTO>(null);
  @Input()
  teamboard: TeamBoardLiteDTO;
}
