<a
  class="text-body"
  [routerLink]="[
    '/formation',
    formationSession.idFormation,
    string_to_slug(formationSession.formationName | ms),
    'session',
    formationSession.idFormationSession,
    string_to_slug(formationSession.formationSessionName),
    formationSession.trainer ? 'trainer' : 'participant',
  ]"
  >
  <div class="bg-white bg-light-30p-hover border rounded-3 overflow-hidden d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto align-icon d-flex align-items-center justify-content-center" style="height: 60px">
        @if (!formationSession.formationImageExtension) {
          <i class="fa-light fa-graduation-cap fa-2x text-tertiary"></i>
        }
        @if (formationSession.formationImageExtension) {
          <img
            class="rounded-2"
            style="height: 60px"
            [img-preloader]="envConfig.configuration.publicFileUrl + 'formation-' + formationSession.idFormation + '.png'"
            />
        }
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto" [ngStyle]="{ color: formationSession.colorCodeFormation }">
            <b>{{ formationSession.formationName | ms }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col-auto" [ngStyle]="{ color: formationSession.colorCodeFormationSession }">
            <b>{{ formationSession.formationSessionName }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col">
            @if (DateUtils.isSameDay(formationSession.minDate, formationSession.maxDate)) {
              <small>{{ formationSession.minDate | localizedDate }}</small>
            }
            @if (!DateUtils.isSameDay(formationSession.minDate, formationSession.maxDate)) {
              <small>
                {{
                'global.Du \{\{startDate\}\} au \{\{endDate\}\}'
                | translate
                : {
                startDate: formationSession.minDate | localizedDate,
                endDate: formationSession.maxDate | localizedDate,
              }
              }}
            </small>
          }
        </div>
      </div>
    </div>
  </div>
</div>
</a>
