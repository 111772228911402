import { Component, OnInit, inject, output, input } from '@angular/core';
import { Router } from '@angular/router';
import { string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { Ic2ToastrService, ManagerInvitationDTO, UserRelationService } from 'common';

@Component({
    selector: 'hiji-manager-invitation-card',
    templateUrl: './manager-invitation-card.component.html',
    styleUrls: ['./manager-invitation-card.component.scss'],
    standalone: false
})
export class ManagerInvitationCardComponent implements OnInit {
  private router = inject(Router);
  private userRelationService = inject(UserRelationService);
  private ic2ToastrService = inject(Ic2ToastrService);
  private translate = inject(TranslateService);

  readonly data = input<ManagerInvitationDTO>(undefined);
  readonly remove = output();

  string_to_slug = string_to_slug;
  acceptMode: boolean = false;
  loading: boolean = false;

  ngOnInit(): void {}

  accept() {
    this.loading = true;
    this.userRelationService
      .acceptInvitationManager(this.data().idUser)
      .onBusinessErrorCode('duplicated_relation', (err) => {
        this.ic2ToastrService.showDanger(this.translate.instant("home.card.manager-invitation.toast.L'invitation à déjà été acceptée"));
      })
      .onOtherErrorTypesUseDefault()
      .onErrorAlwaysDo(() => {
        this.loading = false;
      })
      .execute((data) => {
        this.loading = false;
        this.remove.emit();
      });
  }

  refuse() {
    this.loading = true;
    this.userRelationService
      .refuseInvitationManager(this.data().idUser)
      .onOtherErrorTypesUseDefault()
      .onErrorAlwaysDo(() => {
        this.loading = false;
      })
      .execute((data) => {
        this.loading = false;
        this.remove.emit();
      });
  }
}
