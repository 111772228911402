import { Component, Input, OnInit, inject } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { AuthService, SurveyItemDTO } from 'common';

@Component({
    selector: 'hiji-survey-card',
    templateUrl: './survey-card.component.html',
    styleUrls: ['./survey-card.component.scss'],
    standalone: false
})
export class SurveyCardComponent implements OnInit {
  authService = inject(AuthService);

  @Input()
  survey: SurveyItemDTO;
  warningEndDate: boolean = false;
  string_to_slug = string_to_slug;

  ngOnInit(): void {
    this.warningEndDate = this.verifWarningEndDate();
  }

  verifWarningEndDate() {
    const newDate = new Date(new Date().setDate(new Date().getDate() + 3));
    return newDate >= this.survey.endDate;
  }
}
