<div class="container-fluid bg-light-30p h-100" style="overflow-y: hidden">
  @if (loading) {
    <i class="fa-light fa-spinner spinning"></i>
  }
  @if (!loading) {
    <!--<div class="row mt-3" *ngIf="getNbActions() > 0">
    <div class="col text-center" *ngIf="getNbActions() === 1">
      {{ 'home.Bienvenue, vous avez 1 action en attente' | translate }}
    </div>
    <div class="col text-center" *ngIf="getNbActions() > 1">
      {{ 'home.Bienvenue, vous avez \{\{nb\}\} actions en attente' | translate : { nb: getNbActions() } }}
    </div>
  </div>-->
  @if (data.warningMessage !== null && data.warningMessage !== undefined && data.warningMessage !== '') {
    <div class="row justify-content-center mt-3">
      <div class="col-auto">
        <div class="bg-warning text-white p-2 my-fs-2">{{ data.warningMessage }}</div>
      </div>
    </div>
  }
  @if (coach) {
    <hiji-coach-user-list></hiji-coach-user-list>
  }
  <div class="row" style="overflow-x: hidden">
    <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
      <a class="text-body" routerLink="/user/{{ data.me.idUser }}/{{ string_to_slug(data.me.firstName + '-' + data.me.lastName) }}">
        <hiji-user-card [user]="data.me" [showCallToAction]="true" [withHover]="true"></hiji-user-card>
      </a>
    </div>
    <!-- afficher les invitation des managers -->
    @for (managerInvitation of data.managerInvitations; track managerInvitation) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-manager-invitation-card
          [data]="managerInvitation"
          (remove)="data.managerInvitations.splice(data.managerInvitations.indexOf(managerInvitation), 1)"
        ></hiji-manager-invitation-card>
      </div>
    }
    @for (feedback of data.feedbacks; track feedback) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-feedback-card [feedback]="feedback"></hiji-feedback-card>
      </div>
    }
    <!-- enquêtes à répondre -->
    @for (survey of data.surveys; track survey) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-survey-card [survey]="survey"></hiji-survey-card>
      </div>
    }
    @for (session of data.certificationSessionLiteDTO; track session) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-certification-session-card [certificationSessionLiteDTO]="session"></hiji-certification-session-card>
      </div>
    }
    @if (modulesDTO.moduleTeamBoard) {
      @if (data.team.length > 0 && iDontHaveATeamBoard()) {
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
          <hiji-teamboard-create-card></hiji-teamboard-create-card>
        </div>
      }
      @for (teamboard of data.teamBoards; track teamboard) {
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
          <hiji-teamboard-card [data]="data" [teamboard]="teamboard"></hiji-teamboard-card>
        </div>
      }
    }
    @if (modulesDTO.moduleMIF.enabled) {
      <!-- Les evenements d'un participant -->
      @for (event of data.formationEvents | orderBy: 'startDate'; track event) {
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
          <hiji-mif-event-card [event]="event"></hiji-mif-event-card>
        </div>
      }
      <!-- Les sessions de formation d'un formateur ou d'un participant -->
      @for (session of data.formationSessions | orderBy: 'minDate'; track session) {
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
          <hiji-mif-formation-session-card [formationSession]="session"></hiji-mif-formation-session-card>
        </div>
      }
      <!-- Les attentes d'un manager pour une formation d'un de ses collab -->
      @for (expectation of data.expectations; track expectation) {
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
          <hiji-mif-manager-expectations-card [expectation]="expectation"></hiji-mif-manager-expectations-card>
        </div>
      }
      <!-- L'EVA à rédiger par un manager pour un participant de formation -->
      @for (eva of data.evas; track eva) {
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
          <hiji-mif-eva-card [eva]="eva"></hiji-mif-eva-card>
        </div>
      }
      <!-- Evaluation de son plan d'action -->
      @for (action of data.actions; track action) {
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
          <hiji-mif-participant-evaluation-actions-card [action]="action"></hiji-mif-participant-evaluation-actions-card>
        </div>
      }
    }
    <!-- afficher les campagnes 360 à créer -->
    @for (inquiryToCreate of data.inquiryToCreate; track inquiryToCreate) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-inquiry360-create-card [inquiryToCreate]="inquiryToCreate"></hiji-inquiry360-create-card>
      </div>
    }
    <!-- afficher les campagnes 360 à répondre -->
    @for (inquiryToAnswer of data.inquiryToAnswer; track inquiryToAnswer) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-inquiry360-answer-card [inquiryToAnswer]="inquiryToAnswer"></hiji-inquiry360-answer-card>
      </div>
    }
    <!-- Les sollicitations de feedback -->
    @for (solicitation of data.feedbackSolicitations; track solicitation) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-fbs-solicitation-card [solicitation]="solicitation"></hiji-fbs-solicitation-card>
      </div>
    }
    <!-- son équipe -->
    @for (member of getTeam(true); track member) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <a class="text-body" routerLink="/user/{{ member.idUser }}/{{ string_to_slug(member.firstName + '-' + member.lastName) }}">
          <hiji-user-card [user]="member" [showCallToAction]="true" [withHover]="true"></hiji-user-card>
        </a>
      </div>
    }
    <!-- Les réactions à son feedback -->
    @for (reaction of data.feedbackReactions; track reaction) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-fbs-reaction-card [reaction]="reaction"></hiji-fbs-reaction-card>
      </div>
    }
    <!-- certification rejoindre une session -->
    @if (modulesDTO.moduleCertification) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-rejoindre-certification-card></hiji-rejoindre-certification-card>
      </div>
    }
    <!-- encourager à envoyer / solliciter du FBS -->
    @if (modulesDTO.moduleFBS.enabled && modulesDTO.moduleFBS.displayFBSHomeButton) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <a class="text-body" routerLink="/search/send-fbs">
          <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
            <div class="row g-1 px-2 py-3 flex-grow-1">
              <div class="col-auto d-flex align-items-center">
                <i class="fa-light fa-gift fa-2x text-tertiary align-icon"></i>
              </div>
              <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-auto">
                    <b>{{ 'fbs.menu-card.Envoyer du feedback spontané' | translate }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    }
    @if (modulesDTO.moduleFBS.enabled && modulesDTO.moduleFBS.displayFBSHomeButton) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <a class="text-body" routerLink="/search/sollicitation-fbs">
          <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
            <div class="row g-1 px-2 py-3 flex-grow-1">
              <div class="col-auto d-flex align-items-center">
                <i class="fa-light fa-hand-sparkles fa-2x text-tertiary align-icon"></i>
              </div>
              <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-auto">
                    <b>{{ 'fbs.menu-card.Solliciter du feedback spontané' | translate }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    }
    <!-- encourager à mettre une photo de profil -->
    @if (encouragePicture) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-encourage-picture-card></hiji-encourage-picture-card>
      </div>
    }
  </div>
  <hr />
  <div class="row">
    @if (data.hasGroups && !modulesDTO.frontConfiguration.hideHomeTeamButtons && modulesDTO.moduleFBS.enabled) {
      <div
        class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp"
        >
        <hiji-groups-card></hiji-groups-card>
      </div>
    }
    @if (!data.hasGroups && !modulesDTO.frontConfiguration.hideHomeTeamButtons && modulesDTO.moduleFBS.enabled) {
      <div
        class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp"
        >
        <hiji-group-create-card></hiji-group-create-card>
      </div>
    }
    @for (member of getTeam(false); track member) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <a class="text-body" routerLink="/user/{{ member.idUser }}/{{ string_to_slug(member.firstName + '-' + member.lastName) }}">
          <hiji-user-card [user]="member" [showCallToAction]="true" [withHover]="true"></hiji-user-card>
        </a>
      </div>
    }
    @if (!modulesDTO.frontConfiguration.hideHomeTeamButtons) {
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <hiji-search-card></hiji-search-card>
      </div>
    }
  </div>
  <div class="row">
    @if (
      !modulesDTO.moduleGlobal.manualHierarchicalManagement &&
      (modulesDTO.module180.enabled || modulesDTO.moduleEMA.enabled) &&
      modulesDTO.frontConfiguration.showContactHelp
      ) {
      <div
        class="col-12 p-2 my-fs-2 text-center animate__animated animate__bounceInUp"
        >
        {{ 'team-list.Vous ne trouvez pas un collaborateur ?' | translate }}
        <a class="text-info pointer" routerLink="/contact/missing-colleague">{{ 'team-list.Envoyez-nous un message' | translate }}</a>
      </div>
    }
  </div>
}
</div>
