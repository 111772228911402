import { Component, OnInit, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackSolicitationDTO, UserFuncAvatarDTO } from 'common';
import { FeedbackData } from '../../fbs/feedback.data';

@Component({
    selector: 'hiji-fbs-solicitation-card',
    templateUrl: './fbs-solicitation-card.component.html',
    styleUrls: ['./fbs-solicitation-card.component.scss'],
    standalone: false
})
export class FbsSolicitationCardComponent implements OnInit {
  private router = inject(Router);
  private feedbackData = inject(FeedbackData);

  readonly solicitation = input<FeedbackSolicitationDTO>(undefined);

  ngOnInit(): void {}

  sendFBS(solicitation: FeedbackSolicitationDTO) {
    const u = new UserFuncAvatarDTO();
    u.idUser = solicitation.idUser;
    u.avatarExtension = solicitation.avatarExtension;
    u.firstName = solicitation.firstName;
    u.lastName = solicitation.lastName;
    u.functionName = solicitation.functionName;
    this.feedbackData.reset();
    this.feedbackData.users = [u];
    this.feedbackData.subject = solicitation.subject;
    this.feedbackData.description = solicitation.description;
    this.feedbackData.idFeedbackSolicitation = solicitation.idFeedbackSolicitation;
    this.router.navigate(['/feedback', 'new', this.feedbackData.idFeedbackSolicitation]);
  }
}
